import Link from 'next/link';
import { useSelector } from '@/core';
import { useKsNav } from '@/utils/routes';
import { homeLink, homeLinkLogo } from './home-link.css';

export const HomeLink = () => {
  const { createUrl, isSiteInstanceReady } = useKsNav();

  const { title, logoUrl, hideTitleInNavigation } = useSelector(
    (s) => s.db.settings,
    {
      isEqualityDeep: true,
    },
  );

  const homeLinkUrl = isSiteInstanceReady ? createUrl('/') : '/';
  return (
    <Link className={homeLink} href={homeLinkUrl} data-testid="homeLink">
      {logoUrl && (
        <img
          className={homeLinkLogo}
          alt={title}
          src={logoUrl}
          srcSet={logoUrl}
          data-testid="homeLinkLogo"
        />
      )}
      {!hideTitleInNavigation && (
        <span data-testid="homeLinkText">{title}</span>
      )}
    </Link>
  );
};
