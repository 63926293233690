import { useCanEdit, sendAppClientDataEvent } from '@/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useDroppable,
  useSensor,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { useUsingKeyboard } from '@accessible/using-keyboard';
import { secondaryNavRootKey } from '@/utils/constants';
import { PrimaryNavItem } from './primary-nav-item';
import { primaryNav, primaryNav_list } from './primary-nav.css';
import { useActiveTopNavItem, useNavItems } from '../hooks';

export const PrimaryNav = () => {
  const canEdit = useCanEdit();
  const isUsingKeyboard = useUsingKeyboard(false);

  const activeTopNavItem = useActiveTopNavItem();
  const navItems = useNavItems({ includeHiddenItems: false });
  const topNavItems = navItems.filter(
    (item) => item?.parentId === secondaryNavRootKey,
  );

  // Drag and Drop
  const { setNodeRef } = useDroppable({
    id: 'primary-nav-droppable',
  });
  const sensor = useSensor(PointerSensor, {
    // delay the drag action by x ms and if the cursor is moved x px the delay is reset
    activationConstraint: {
      delay: 150,
      tolerance: 5,
    },
  });

  return (
    <DndContext
      sensors={[sensor]}
      onDragEnd={(e: DragEndEvent) => {
        const { active, over } = e;
        const oldIndex = topNavItems.findIndex((item) => item.id === active.id);
        const newIndex = topNavItems.findIndex((item) => item.id === over?.id);

        if (oldIndex !== newIndex && newIndex !== -1) {
          const updatedItems = [...topNavItems];
          const [removed] = updatedItems.splice(oldIndex, 1);
          updatedItems.splice(newIndex, 0, removed);
          sendAppClientDataEvent({
            type: 'navs.primary.changeOrder',
            id: active.id.toString(),
            index: newIndex,
          });
        }
      }}
      modifiers={[restrictToHorizontalAxis]}
    >
      <SortableContext items={topNavItems} disabled={!canEdit}>
        <nav
          id="primaryNav"
          className={clsx(primaryNav, {
            'is-using-keyboard': isUsingKeyboard,
          })}
          data-testid="primaryNav"
          aria-label="Primary"
        >
          <ul ref={setNodeRef} className={primaryNav_list({ canEdit })}>
            {topNavItems.map((item) => {
              return (
                <PrimaryNavItem
                  canEdit={canEdit}
                  isActive={activeTopNavItem?.id === item.id}
                  navItem={item}
                  key={item.id}
                />
              );
            })}
          </ul>
        </nav>
      </SortableContext>
    </DndContext>
  );
};
