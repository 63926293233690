import { rolesById, type Role } from '@knapsack/types';
import { useIsSitePrivate } from '../xstate';

export function useUserCanAccessItem({
  userRole,
  minRoleNeeded,
}: {
  userRole: Role;
  minRoleNeeded: Role;
}) {
  const isSitePrivate = useIsSitePrivate();
  const fallbackRole: Role = isSitePrivate ? 'VIEWER' : 'ANONYMOUS';
  const userRoleHierarchy = rolesById[userRole].hierarchy;
  const minRoleHierarchy = rolesById[minRoleNeeded || fallbackRole].hierarchy;

  return userRoleHierarchy >= minRoleHierarchy;
}
