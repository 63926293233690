import { lazy } from 'react';
import { useCanEdit } from '@/core';
import { useActiveTopNavItem } from '../hooks';

const AddNewTrigger = lazy(
  () => import('@/domains/navs/components/add-new/add-new-trigger'),
);

export const SecondaryNavFooter = () => {
  const canEdit = useCanEdit();
  const activeTopNavItem = useActiveTopNavItem();

  return (
    canEdit && (
      <AddNewTrigger
        menuPlacement="right"
        parentId={activeTopNavItem?.id}
        testId="addEntity--secondaryNav--button"
        trigger={{
          type: 'button',
          trackEventTriggerType: 'secondary-nav-button',
        }}
      />
    )
  );
};
